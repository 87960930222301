import React, { useState } from 'react';
import { Button, Row, Container, Card, CardGroup, Form, Alert } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import axios from '../api/axios';
import printerImg from '../asset/img/ifusion450.svg';
import intechLogo from '../asset/img/Intech_logo.png';

const verifyEmailUrl = process.env.REACT_APP_RESET_SEND_EMAIL

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false); // to check whether the email exist or not
  const navigate = useNavigate();
  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    try {
        await axios
            .post(verifyEmailUrl, { email })
            .then((response) => {
                console.log(response)
                setSuccess(true);
                setTimeout(() => {
                    navigate("/", { replace: true });
                }, 4000);
            })
            .catch((err) => {
                setFailure(true);
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};
  return (
    <>
    {success ? (
        <section>
            <h1>Success!</h1>
            <p>
                <Alert variant='info'>Check your email for verification link</Alert>
            </p>
        </section>
    ) : (
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <CardGroup className="shadow p-0">
                    <Card>
                        <Card.Img variant="top" src={printerImg} />  
                    </Card>

                    <Card >
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                                <Row className="pb-3">
                                    <Card.Img variant="top" src={intechLogo} style={{ width: '18rem' }}/>
                                </Row>
                                <Row className="pb-3">
                                    <h2 className="fs-3 mb-2 text-uppercase ">Reset Password</h2>
                                </Row>
                                {failure && (
                                    <Alert variant='danger'>The email entered doesn't exist.</Alert>
                                )}
                                <Row className="">
                                    <div>
                                        <Form onSubmit={handleEmailSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Email address
                                                    </Form.Label>
                                                    <Form.Control 
                                                        type="email" 
                                                        placeholder="Enter email" 
                                                        id="email"
                                                        autoComplete="off"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        value={email}
                                                        required/>
                                            </Form.Group>
                                            <div className="d-grid">
                                                <Button 
                                                    variant="dark" 
                                                    type="submit" 
                                                    >
                                                    Submit
                                                </Button>
                                            </div>
                                            <div className="mt-3">
                                                <p className="mb-0  text-center">
                                                    Remember your password?{" "}
                                                    <Link to="/" className="text-primary fw-bold">Login</Link>    
                                                </p>
                                            </div>
                                        </Form>
                                    </div>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Row>
        </Container>
    )}
    </>
  );
};

export default ResetPassword;
