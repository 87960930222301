import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import {
	Button,
	Row,
	Container,
	Card,
	CardGroup,
	Form,
	Alert,
} from "react-bootstrap";
import axios from 'axios';
import printerImg from "../asset/img/ifusion450.svg";
import intechLogo from "../asset/img/Intech_logo.png";

const loginUrl = process.env.REACT_APP_LOGIN_URL;
if (!loginUrl) {
	throw new Error('REACT_APP_LOGIN_URL is not defined in the environment.');
}
const Login = () => {
	const userRef = useRef();
	const [showError, setShowError] = useState(false);
	const [showUnverifiedError, setShowUnverifiedError] = useState(false);

	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");

	useEffect(() => {
		userRef.current.focus();
		document.title = "Login";
	}, []);

	function setSession(authResult) {
		const cookies = new Cookies({ domain: ".intechadditivesoftware.com" });
		
		// Set the time that the access token will expire at
		const expiresAt = JSON.stringify(
			authResult.auth_time * 1000 + new Date().getTime()
		);
		cookies.set("access_token", authResult.accessToken, {
			domain: ".intechadditivesoftware.com",
			sameSite: "none",
			secure: true,
		});
		cookies.set("id_token", authResult.idToken, {
			domain: ".intechadditivesoftware.com",
			sameSite: "none",
			secure: true,
		});
		cookies.set("expires_at", expiresAt, { domain: ".intechadditivesoftware.com" });

		if (authResult.refreshToken) {
			cookies.set("refresh_token", authResult.refreshToken, {
				domain: ".intechadditivesoftware.com",
			});
		}
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		setShowError(false);
		setShowUnverifiedError(false);

		await axios
			.post(loginUrl, JSON.stringify({
				email: email,
				password: pwd
			}), { headers: { "content-type": "application/json" } })
			.then((response) => {
				console.log(response.data);
				setSession(response.data);
				setEmail("");
				setPwd("");
				setShowError(false);
				setShowUnverifiedError(false);
				window.location.href = process.env.REACT_APP_DASHBOARD_URL;
			})
			.catch((error) => {
				const statusCode = error.response?.status;
				if (statusCode === 403) {
					setShowError(true);
				} else if (statusCode === 401) {
					setShowUnverifiedError(true);
				} else {
					console.log(error);
				}
			});
	};

	return (
		<Container>
			<Row className="vh-100 d-flex justify-content-center align-items-center">
				<CardGroup className="shadow p-0">
					<Card>
						<Card.Img variant="top" src={printerImg} />
					</Card>

					<Card>
						<Card.Body>
							<div className="mb-3 mt-md-4">
								<Row className="pb-3">
									<Card.Img
										variant="top"
										src={intechLogo}
										style={{ width: "18rem" }}
									/>
								</Row>
								<Row className="pb-3">
									<h2 className="fs-3 mb-2 text-uppercase ">Login</h2>
								</Row>
								{showError && (
									<Alert variant="danger">Invalid email or password</Alert>
								)}
								{showUnverifiedError && (
									<Alert variant="danger">
										Unauthorized access. Verify your Email.
									</Alert>
								)}
								<Row className="">
									<Form onSubmit={handleSubmit}>
										<Row>
											<Form.Group className="mb-3">
												<Form.Label className="text-center">
													Email address
												</Form.Label>
												<Form.Control
													type="email"
													placeholder="Enter email"
													id="email"
													ref={userRef}
													autoComplete="off"
													onChange={(e) => setEmail(e.target.value)}
													value={email}
													required
												/>
											</Form.Group>

											<Form.Group className="mb-3">
												<Form.Label>Password</Form.Label>
												<Form.Control
													type="password"
													id="password"
													onChange={(e) => setPwd(e.target.value)}
													value={pwd}
													required
													placeholder="Password"
												/>
											</Form.Group>

											<Form.Group className="mb-3">
												<p className="small">
													<Link to="/reset-password">Forgot password?</Link>
												</p>
											</Form.Group>
										</Row>
										<div className="d-grid">
											<Button variant="dark" type="submit">
												Login
											</Button>
										</div>
									</Form>
									<div className="mt-3">
										<p className="mb-0  text-center">
											Don't have an account?{" "}
											<Link to="/register" className="text-primary fw-bold">
												Sign Up
											</Link>
										</p>
									</div>
								</Row>
							</div>
						</Card.Body>
					</Card>
				</CardGroup>
			</Row>
		</Container>
	);
};

export default Login;
