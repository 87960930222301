import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import { Routes, Route } from 'react-router-dom';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset-password" element={< ResetPassword/>} />
    </Routes> 
  );
}

export default App;
