import React from "react";
import { useRef, useState, useEffect } from "react";
import {
	Button,
	Row,
	Container,
	Card,
	CardGroup,
	Form,
	Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import CountrySelect from "react-bootstrap-country-select";

import axios from "axios";
import printerImg from "../asset/img/ifusion450.svg";
import intechLogo from "../asset/img/Intech_logo.png";

const registerUrl = process.env.REACT_APP_REGISTER_URL;

export default function Register() {
	const userRef = useRef();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [matchPwd, setMatchPwd] = useState("");
	const [jobTitle, setJobTitle] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [countryName_, setCountryName] = useState("");

	const [validatedPwd, setValidatedPwd] = useState(false);
	const [validatedMatchPwd, setValidatedMatchPwd] = useState(false);
	const [validatedMobileNum, setValidatedMobileNum] = useState(false);
	const [validatedEmail, setValidatedEmail] = useState(false);

	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [countryNameError, setCountryNameError] = useState(false);
	const [existingUserError, setExistingUserError] = useState(false);

	useEffect(() => {
		userRef.current.focus();
		document.title = "Register";
	}, []);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setValidatedEmail(false);
	};

	const handlePasswordChange = (event) => {
		setPwd(event.target.value);
		setValidatedPwd(false);
	};

	const handleConfirmPasswordChange = (event) => {
		setMatchPwd(event.target.value);
		setValidatedMatchPwd(false);
	};

	const handleMobileNumberChange = (value) => {
		setMobileNumber(value);
		setValidatedMobileNum(false);
	};

	const handleCountryNameChange = (value, event) => {
		setCountryName(value);
		setCountryNameError(false);
	};

	const validateEmail = () => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const validatePassword = () => {
		const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
		return regex.test(pwd);
	};

	const validateConfirmPassword = () => {
		return pwd === matchPwd;
	};

	const validateMobileNumber = () => {
		const regex = /^[0-9]{10}$/;
		return regex.test(mobileNumber);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
        const form = e.currentTarget;

		if (form.checkValidity() === false) {
			e.stopPropagation();
		} else {
			let countryName = countryName_.name;

			if (!countryName) {
				setCountryNameError(true);
				return;
			}

			try {
				const response = await axios
					.post(
						registerUrl,
						JSON.stringify({
							firstName,
							lastName,
							email,
							pwd,
							jobTitle,
							mobileNumber,
							companyName,
							countryName,
						}),
						{
							headers: { "Content-Type": "application/json" }
						}
					)
					.catch((err) => {
						console.log(err);
						if (err.response.status === 409) {
							setErrorMessage("Email is already taken");
						}
						setExistingUserError(true);
						
					});

				


				console.log(JSON.stringify(response?.data));

				setSuccess(true);
				setValidatedPwd(true);
				setValidatedMatchPwd(true);
				setValidatedMobileNum(true);
				setValidatedEmail(true);
				setTimeout(() => {
					window.location.href = process.env.REACT_APP_AUTH_URL;
				}, 4000);
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<>
			{success ? (
				<section>
					<h1>Success!</h1>
					<p>
						{/* <Navigate to={'/'}/> */}
						<Alert variant="info">Check your email for verification link</Alert>
					</p>
				</section>
			) : (
				<Container>
					<Row className="vh-100 d-flex justify-content-center align-items-center">
						<CardGroup className="shadow p-0">
							<Card className="justify-content-center align-items-center">
								<Card.Img
									variant="top"
									src={printerImg}
									style={{ height: "55rem" }}
								/>
							</Card>
							<Card>
								<Card.Body>
									<div className="mb-3 mt-md-4">
										<Row className="pb-3">
											<Card.Img
												variant="top"
												src={intechLogo}
												style={{ width: "18rem" }}
											/>
										</Row>
										<Row className="pb-3">
											<h2 className="fs-3 mb-2 text-uppercase ">Sign Up</h2>
										</Row>
										{existingUserError && (
											<Alert variant="danger">User already exist</Alert>
										)}
										<Row className="">
											<Form noValidate onSubmit={handleSubmit}>
												{errorMessage && (
													<Alert variant="danger">{errorMessage}</Alert>
												)}
												<Row>
													<Form.Group className="col mb-3">
														<Form.Label className="text-center">
															First Name
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="text"
															controlId="firstName"
															ref={userRef}
															placeholder="First name"
															aria-label="First name"
															onChange={(e) => setFirstName(e.target.value)}
															value={firstName}
															required
														/>
													</Form.Group>
													<Form.Group className="col mb-3">
														<Form.Label className="text-center">
															Last Name
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="text"
															controlId="lastName"
															placeholder="Last name"
															onChange={(e) => setLastName(e.target.value)}
															value={lastName}
															required
															aria-label="Last name"
														/>
													</Form.Group>
													<Form.Group
														className="mb-3"
														controlId="formBasicEmail"
													>
														<Form.Label className="text-center">
															Email Address
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="email"
															placeholder="Enter email"
															value={email}
															onChange={handleEmailChange}
															onBlur={() => setValidatedEmail(true)}
															required
															autoComplete="off"
															isValid={validatedEmail && validateEmail()}
															isInvalid={validatedEmail && !validateEmail()}
														/>
														<Form.Control.Feedback type="invalid">
															Please provide a valid email address.
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="mb-3" controlId="password">
														<Form.Label>
															Password{" "}
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="password"
															placeholder="Enter password"
															value={pwd}
															onChange={handlePasswordChange}
															onBlur={() => setValidatedPwd(true)}
															required
															isValid={
																validatedPwd &&
																pwd.length >= 8 &&
																validatePassword()
															}
															isInvalid={
																validatedPwd &&
																(pwd.length === 0 ||
																	pwd.length < 8 ||
																	!validatePassword())
															}
														/>
														<Form.Control.Feedback type="invalid">
															Password must be at least 8 characters long and
															contain at least one uppercase letter, one
															lowercase letter, and one number.
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group
														className="mb-3"
														controlId="confirmPassword"
													>
														<Form.Label>
															Confirm Password
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="password"
															placeholder="Confirm password"
															value={matchPwd}
															onChange={handleConfirmPasswordChange}
															onBlur={() => setValidatedMatchPwd(true)}
															required
															isValid={
																validatedMatchPwd && validateConfirmPassword()
															}
															isInvalid={
																validatedMatchPwd && !validateConfirmPassword()
															}
														/>
														<Form.Control.Feedback type="invalid">
															Passwords must match.
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="mb-3" controlId="jobTitle">
														<Form.Label>
															Job Title
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="text"
															onChange={(e) => setJobTitle(e.target.value)}
															value={jobTitle}
															placeholder="Designation"
														/>
													</Form.Group>
													<Form.Group className="mb-3" controlId="mobileNumber">
														<Form.Label>Mobile number</Form.Label>
														<PhoneInput
															country={"in"}
															value={mobileNumber}
															onChange={handleMobileNumberChange}
															placeholder="Enter mobile number"
															onBlur={() => setValidatedMobileNum(true)}
															isValid={
																validatedMobileNum && validateMobileNumber()
															}
															isInvalid={
																validatedMobileNum && !validateMobileNumber()
															}
															inputProps={{
																required: false,
																autoFocus: true,
																autoComplete: "tel",
																className: "form-control",
															}}
														/>
														<Form.Control.Feedback type="invalid">
															Please provide a valid mobile number.
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="mb-3" controlId="companyName">
														<Form.Label>
															Company Name
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<Form.Control
															type="text"
															required
															onChange={(e) => setCompanyName(e.target.value)}
															value={companyName}
															placeholder="company name"
														/>
													</Form.Group>
													<Form.Group className="mb-3" controlId="country">
														<Form.Label>
															Country
															<span className="text-muted font-weight-light">
																(required)
															</span>
														</Form.Label>
														<CountrySelect
															value={countryName_}
															onChange={handleCountryNameChange}
															required
															valueAs="country"
														/>
														{countryNameError && (
															<Form.Text className="text-danger">
																Enter country
															</Form.Text>
														)}
													</Form.Group>
												</Row>
												<div className="d-grid p-2">
													<Button
														className="fs-5"
														variant="dark"
														type="submit"
														disabled={
															!validateEmail() ||
															!validatePassword() ||
															!validateConfirmPassword()
														}
													>
														Sign Up
													</Button>
												</div>
											</Form>
											<div className="mt-3">
												<p className="mb-0  text-center">
													Already have an account?{" "}
													<Link to="/" className="text-primary fw-bold">
														Login
													</Link>
												</p>
											</div>
										</Row>
									</div>
								</Card.Body>
							</Card>
						</CardGroup>
					</Row>
				</Container>
			)}
		</>
	);
}
